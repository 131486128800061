import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'
import BannerDesktop from './assets/images/previdencia-privada-banner-desktop.png'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }
`

export const ContentHeader = styled.section`

  @media ${device.tablet} {
    background-image: url(${BannerDesktop});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 720px;
    overflow: hidden;
  }

  @media ${device.desktopXL} {
    height: 100vh;
    max-height: 800px;
    overflow: hidden;
  }

  @media ${device.desktopXXL} {
    height: 100vh;
    max-height: 805px;
  }

  @media (min-width: 1920px) {
    height: 100vh;
    max-height: 1080px;
  }

  .bread-crumb {
    span {
      a {
        color: ${white}
      }
    }
  }
`

export const OpenVideoButton = styled.button`
  position: absolute;
  left: 50%;
  bottom: 75px;
  transform: translateX(-50%);
  z-index: 1;

  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    bottom: auto;
    top: 50%;
    left: 80%;
    transform: translate(-35%, -50%);
  }

  @media ${device.desktopXL} {
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const WrapperIcon = styled.div`
  background-color: #f1f2f7;
  height: 38px;
  width: 38px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media ${device.tablet} {
    height: 68px;
    width: 68px;
  }
`

type CardOptionProps = {
  profile: string;
}

export const CardOption = styled.div<CardOptionProps>`
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: ${white};
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  padding: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 15px;

    ${(props: CardOptionProps) => {
      switch (props.profile) {
        case 'Conservador':
          return css`
            background-color: #4CB1E7;
          `
        case 'Moderado':
          return css`
            background-color: #FFC700;
          `
        case 'Arrojado':
          return css`
            background-color: #F56A50;
          `
      }
    }}
  }
`

export const VideoOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.25);
`
