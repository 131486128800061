import Img from 'gatsby-image'
import React, { ChangeEvent, useState } from 'react'

// Types
import { DataImage, Values } from '../../types'

type EnterYourNumbersProps = {
  values?: Values;
  setValues: (value: Values) => void;
  setStep: (value: string) => void;
  data: DataImage;
}

const EnterYourNumbers = ({ values, setValues, setStep, data }: EnterYourNumbersProps) => {
  const [ message, setMessage ] = useState<string>()
  const periodList = [ 10, 15, 20, 25, 30, 35 ]

  function currencyToFloat (currencyNumber: string) {
    const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return result
  }

  function floatToCurrency (floatNumber: number) {
    return floatNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const { name, value }: HTMLInputElement = evt.currentTarget
    setValues({
      ...values,
      [name]: currencyToFloat(value),
    })
  }

  function handleClick (period: number) {
    setValues({
      ...values,
      period,
    })
  }

  function validateNumbers () {
    if (values?.initialContribution) {
      if (values?.monthlyContribution) {
        // Mínimo deve ser 100 para OS DOIS
        if (values.initialContribution < 100 || values.monthlyContribution < 100) {
          return 'Os aportes inicial e mensal não podem ser menores que R$ 100,00'
        }
      } else {
        // Mínimo deve ser 1000 para o inicial
        if (values.initialContribution < 1000) {
          return 'O aporte inicial não pode ser menor que R$ 1000,00'
        }
      }
    } else if (values?.monthlyContribution) {
      // Mínimo deve ser 100
      if (values?.monthlyContribution < 100) {
        return 'O aporte mensal não pode ser menor que R$ 100,00'
      }
    } else {
      return 'Preencha pelo menos um dos aportes'
    }
  }

  function handleSubmit () {
    const errorMessage = validateNumbers()
    if (errorMessage) {
      setMessage(errorMessage)
    } else {
      setStep('set-taxation')
    }
  }

  return (
    <>
      <header>
        <h4 className='font-sora fw-600 fs-20 lh-25 text-grayscale--400 my-2 my-md-4 fs-md-32 lh-md-40'>
          <small className='fs-14 lh-17 fs-md-20 lh-md-25'>02.</small> Informe seus números
        </h4>
      </header>
      <div className='px-3 py-4 rounded-5 row align-items-center justify-content-center bg-grayscale--100 my-4 my-md-5 px-md-0'>
        <div className='col-md-10 col-lg-5 col-xl-4 px-lg-0'>
          {
            values?.mode === 'pgbl'
              ? (
                <div className='pb-4 py-lg-0 text-center border-bottom border-md-bottom-0'>
                  <label htmlFor='initialContribution' className='font-sora fw-600 fs-14 lh-17 text-center text-grayscale--400 mb-3 fs-md-16 lh-md-20 mb-md-3'>Quanto você declara anualmente em seu Imposto de Renda?</label>
                  <div className='d-flex align-items-center'>
                    <span className='fs-22 lh-27 text-grayscale--700'>R$</span>
                    <input
                      type='text'
                      inputMode='numeric'
                      maxLength={14}
                      className='font-sora fw-600 fs-32 lh-40 text-orange--extra border-0 bg-transparent mw-100 flex-grow-1 text-center fs-lg-48 lh-lg-60 pl-1 pr-4'
                      id='incomeTax'
                      name='incomeTax'
                      value={floatToCurrency(values?.incomeTax || 0)}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )
              : <Img className='d-none d-lg-block my-lg-n5 mx-auto' fluid={data.informeSeusNumeros.fluid} />
          }
        </div>
        <div className='col-12 col-md-10 col-lg-6 offset-xl-1 px-lg-0'>
          <div className='row'>
            <div className='col-12 col-md-6 text-center border-bottom border-md-bottom-0 border-md-right py-4 py-md-0'>
              <label htmlFor='initialContribution' className='font-sora fw-600 fs-14 lh-17 text-center text-grayscale--400 mb-3 fs-md-16 lh-md-20 mb-md-3'>Aporte inicial</label>
              <div className='d-flex align-items-center'>
                <span className='fs-22 lh-27 text-grayscale--700'>R$</span>
                <input
                  type='text'
                  inputMode='numeric'
                  maxLength={14}
                  className='font-sora fw-600 fs-32 lh-40 text-orange--extra border-0 bg-transparent mw-100 flex-grow-1 text-center pl-1 pr-4'
                  id='initialContribution'
                  name='initialContribution'
                  value={floatToCurrency(values?.initialContribution || 0)}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='col-12 col-md-6 text-center border-bottom border-md-bottom-0 py-4 py-md-0'>
              <label htmlFor='monthlyContribution' className='font-sora fw-600 fs-14 lh-17 text-center text-grayscale--400 mb-3 fs-md-16 lh-md-20 mb-md-3'>Aporte mensal</label>
              <div className='d-flex align-items-center'>
                <span className='fs-22 lh-27 text-grayscale--700'>R$</span>
                <input
                  type='text'
                  inputMode='numeric'
                  maxLength={13}
                  className='font-sora fw-600 fs-32 lh-40 text-orange--extra border-0 bg-transparent mw-100 flex-grow-1 text-center pl-1 pr-4'
                  id='monthlyContribution'
                  name='monthlyContribution'
                  value={floatToCurrency(values?.monthlyContribution || 0)}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='col-9 mx-auto py-4 text-center px-md-3 col-md-12 '>
              <label htmlFor='years' className='font-sora fw-600 fs-14 lh-17 text-grayscale--400 mb-3 fs-md-16 lh-md-20 mb-md-3'>Por quantos anos?</label>
              <div className='d-flex justify-content-center align-items-center flex-wrap'>
                {
                  periodList.map((period: number) => (
                    <button onClick={() => handleClick(period)} key={period} className={`font-sora fw-600 fs-16 lh-20 m-2 p-3 border-0 rounded-4 ${values?.period === period ? 'bg-orange--extra text-white' : 'bg-white text-grayscale--300'}`}>{period}</button>
                  ))
                }
              </div>
            </div>
            <div className='col-12 px-0'>
              { message && <p className='fw-600 fs-14 lh-17 text-center text-red--base'>{message}</p> }
              <button
                disabled={!values?.period || (values.mode === 'pgbl' && !values.incomeTax)}
                className='btn bg-orange--extra text-white text-none rounded-3 fw-600 fs-14 lh-17 mx-auto d-block'
                title='Calcular minha previdência'
                onClick={handleSubmit}
              >
                Calcular minha previdência
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EnterYourNumbers
