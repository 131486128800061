import { grayscale, green } from 'src/styles/colors';
import styled from 'styled-components';
import { TaxValue } from './_index';

interface ITabCategoryProps {
  status: number;
}

interface IBorderlineProps {
  status: number;
}

export const TabCategory = styled.div<ITabCategoryProps>`
  width: 100%;
  height: 22px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
  margin-bottom: 24px;

  ${(props: ITabCategoryProps) => props.status === TaxValue.BAIXO && `
    background: ${green[400]};
  `}


  ${(props: ITabCategoryProps) => props.status === TaxValue.MODERADO && `
    background: #FFC700;
  `}


  ${(props: ITabCategoryProps) => props.status === TaxValue.ALTO && `
    background: #F56A50;
  `}
`

export const CardFund = styled.div`
  background: white;
  border: 1px solid ${grayscale[200]};
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 12px;

  .category-info {
    margin: 14px 10px;
    height: 138px;
    display: flex;
    align-items: center;

    p {
      font-family: "Inter", Helvetica, sans-serif;
    }
  }
`

export const Borderline = styled.div<IBorderlineProps>`
  min-width: 4px;
  height: 100%;
  border-radius: 24px;
  margin-right: 36px;

  ${(props: IBorderlineProps) => props.status === TaxValue.BAIXO && `
    background: ${green[400]};
  `}


  ${(props: IBorderlineProps) => props.status === TaxValue.MODERADO && `
    background: #FFC700;
  `}


  ${(props: IBorderlineProps) => props.status === TaxValue.ALTO && `
    background: #F56A50;
  `}
`

export const FooterFunds = styled.div`
  height: 68px;
  padding: 16px;
  background: ${grayscale[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${grayscale[200]};
`

export const Values = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const Percentage = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: ${grayscale[500]};
`
