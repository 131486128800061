import React, { ReactNode } from 'react'
import YoutubeVideo from 'components/YoutubeVideo'
import { Modal } from 'components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

type OpenVideoProps = {
  link: string;
  children: ReactNode;
  onClick: Function;
}

function VideoModal ({ children, link, onClick }: OpenVideoProps) {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false)

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleOpen = () => {
    setIsModalOpen(true)
    if (onClick) {
      onClick()
    }
  }

  return (
    <>
      <div onClick={handleOpen}>
        {children}
      </div>
      {domReady && (
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
          <div className='modal'>
            <div className='modal__backdrop' onClick={() => handleClose()} />
            <div className='modal__content'>
              <div className='modal__content__close_button' onClick={() => handleClose()}>
                X
              </div>
              <YoutubeVideo
                src={link}
                width='100%'
                height='100%'
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

VideoModal.defaultProps = {
  link: '',
  children: <></>,
}

export default VideoModal
